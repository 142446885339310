import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined"
import Box from "@mui/material/Box"
import Modal from "@mui/material/Modal"
import Typography from "@mui/material/Typography"
import * as React from "react"
import * as S from "./style"
import { useState } from "react"
import { TextField } from "@mui/material"
import api from "../../../services"

const style = {
  position: "absolute",
  borderRadius: "10px",
  width: `40%`,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  maxHeight: "80vh",
  outline: `1px solid #d9d9d9`,
}

export default function RepproveModal({ handleValidate }) {
  const [open, setOpen] = useState(false);
  const [reason, setReason] = useState("");

  return (
    <>
      <S.OutlineButton onClick={() => setOpen(true)}>
        Reprovar
      </S.OutlineButton>
      {open && (
        <Modal
          open={open}
          onClose={setOpen}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <S.Icon>
              <CloseOutlinedIcon onClick={() => setOpen(false)} />
            </S.Icon>

            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <S.ContainerTitle>
                <S.Text>Repovar Alteração</S.Text>
              </S.ContainerTitle>
              <Box sx={{ overflowY: "auto", maxHeight: "55vh" }}>
                <S.SubtitleWrapper>
                  Motivo
                  <TextField
                    value={reason}
                    onChange={(ev) => setReason(ev.currentTarget.value)}
                  />
                </S.SubtitleWrapper>
              </Box>
              <S.BottomInfo>
                <S.BottomButtons>
                  <S.DefaultButton onClick={() => {
                    setOpen(false);
                    handleValidate(false, reason);
                  }}>
                    <p>Reprovar</p>
                  </S.DefaultButton>
                </S.BottomButtons>
              </S.BottomInfo>
            </Typography>
          </Box>
        </Modal>
      )}
    </>
  )
}