import { CircularProgress, Pagination } from "@mui/material"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import { useEffect, useState } from "react"
import MainLayout from "../../layouts/MainLayout"
import api from "../../services"
import * as S from "./style"
import ValidateChangeRequest from "./validate-modal"

export default function AnnouncementChangeRequest() {
  const [search, setSearch] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useState(search);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [loading, setLoading] = useState(false);
  const [changeRequests, setChangeRequests] = useState([]);

  async function fetchChangRequests() {
    try {
      setLoading(true)
      const res = await api.get("/api/admin/announcement-change-request", {
        params: {
          page: page,
          ...(search && { search: debouncedSearch }),
        }
      })

      setChangeRequests(res.data.data);
      setPageCount(res.data.last_page);
      setLoading(false)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    fetchChangRequests();
  }, [page, debouncedSearch]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedSearch(search.toLowerCase());
    }, 300);
  
    return () => clearTimeout(timer);
  }, [search]);
  
  
  return (
    <MainLayout title="Solicitação de Alteração em Anúncio">
      <S.Header>
        <S.Input
          placeholder="Busque pelo id do anúncio"
          onChange={(e) => setSearch(e.target.value )}
        />
      </S.Header>
      {loading ? (
        <S.ContainerLoading>
          <CircularProgress style={{ color: "#919DF2" }} />
        </S.ContainerLoading>
      ) : (
        <>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="consumer table">
              <TableHead sx={{ backgroundColor: "#B2B2B2" }}>
                <TableRow>
                  <TableCell>ID Anúncio</TableCell>
                  <TableCell>Nome</TableCell>
                  <TableCell>Localização</TableCell>
                  <TableCell>Ramo de Negócio</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {changeRequests?.length > 0 && changeRequests?.map((item) => (
                  <TableRow
                    key={item.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell>{item.idanuncio}</TableCell>
                    <TableCell>{item.name.slice(0, 15).trim()}{item.name.trim().length > 14 ? "..." : ""}</TableCell>
                    <TableCell>{item.location.length === 2 ? `${item.location[1]}/${item.location[0]}` : "-"}</TableCell>
                    <TableCell>{item.ramo_negocio.descricao}</TableCell>
                    <TableCell align="right">
                      <ValidateChangeRequest fetchChangRequests={fetchChangRequests} changeRequest={item}/>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {changeRequests?.length <= 0 && (
            <div style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px"
            }}>
              Nenhum registro encontrado
            </div>
          )}
          <div style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px"
          }}>
            <Pagination count={pageCount} page={page} onChange={(_, v) => setPage(v)}/>
          </div>
        </>
      )}
    </MainLayout>
  )
}