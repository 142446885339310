import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import api from "../../../services";
import MainLayout from "../../../layouts/MainLayout";
import * as S from "./style";

const Section = ({ title, fields, media }) => (
  <S.Section>
    <h2>{title}</h2>
    <S.InformationGroup>
      {fields.map((field, index) => (
        <S.Information key={index}>
          <strong>{field.label}:</strong>
          <p>{field.value || "-"}</p>
        </S.Information>
      ))}
    </S.InformationGroup>
    {media && (
      <S.MediaGroup>
        {media.map((item, index) => (
          item.type === "image" && item.url ? (
            <S.MediaItem key={`image-${index}`}>
              <img src={item.url} alt={`Imagem ${index + 1}`} />
            </S.MediaItem>
          ) : item.type === "video" && item.url ? (
            <S.MediaItem key={`video-${index}`}>
              <video controls>
                <source src={item.url} type="video/mp4" />
                Seu navegador não suporta o vídeo.
              </video>
            </S.MediaItem>
          ) : null
        ))}
      </S.MediaGroup>
    )}
  </S.Section>
);

const Detail = () => {
  const { type, id } = useParams();
  const [userDetails, setUserDetails] = useState(null);
  const [anuncioMedia, setAnuncioMedia] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  console.log("Dados do usuário:", userDetails);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const endpoint = type === "anunciante" ? `/api/admin/users/${id}` : `/api/admin/users/consumidores/${id}`;
        const response = await api.get(endpoint);
        setUserDetails(response.data);

        console.log(response.data)
        if (type === "anunciante") {
          const midiaEndpoint = `/api/admin/users/anuncio/${id}`;
          const responseMidia = await api.get(midiaEndpoint);

          const validMedia = responseMidia.data.midia.filter(
            (item) => (item.type === "image" || item.type === "video") && item.url
          );
          setAnuncioMedia(validMedia);

          
        }
      } catch {
        setError("Erro ao buscar os detalhes do usuário");
      } finally {
        setLoading(false);
      }
    };
    fetchUserDetails();
  }, [type, id]);

  if (loading) return <p>Carregando...</p>;
  if (error) return <p>{error}</p>;

  return (
    <MainLayout>
      <h1>Detalhes do {type === "anunciante" ? "Anunciante" : "Consumidor"}</h1>
      {userDetails ? (
        <S.AllContainer>
          <S.Content>
            {type === "anunciante" ? (
              <>
                <S.ContentRow>
                  <Section
                    title="Informações Pessoais"
                    fields={[
                      { label: "Nome", value: userDetails.name },
                      { label: "Razão Social", value: userDetails.razaosocial },
                      { label: "Nome Fantasia", value: userDetails.fantasia },
                      { label: "CNPJ", value: userDetails.CNPJ },
                      { label: "CPF", value: userDetails.CPF },
                      { label: "Telefone", value: userDetails.telefone },
                      { label: "Celular", value: userDetails.celular },
                      { label: "Email", value: userDetails.email },
                      { label: "Descrição", value: userDetails.descricao },
                    ]}
                  />
                  <S.Content>
                    <Section
                      title="Redes Sociais"
                      fields={[
                        { label: "Facebook", value: userDetails.facebook },
                        { label: "LinkedIn", value: userDetails.linkedin },
                        { label: "Instagram", value: userDetails.instagram },
                        { label: "Twitter", value: userDetails.twitter },
                        { label: "Pinterest", value: userDetails.pinterest },
                      ]}
                    />
                    <Section
                      title="Endereço"
                      fields={[
                        { label: "Endereço", value: userDetails.endereco },
                        { label: "Bairro", value: userDetails.bairro },
                        { label: "Cidade", value: userDetails.cidade_nome },
                        { label: "CEP", value: userDetails.CEP },
                        { label: "Estado", value: userDetails.estado_nome },
                      ]}
                    />
                  </S.Content>
                </S.ContentRow>

                <Section
                  title="Anúncio"
                  fields={[
                    { label: "Nome", value: userDetails.anuncio_nome },
                    { label: "Logo", value: userDetails.anuncio_logo },
                    { label: "Descrição", value: userDetails.anuncio_descricao },
                    { label: "Estabelecimento", value: userDetails.anuncio_estabelecimento },
                  ]}
                />
                <Section
                  title="Galeria do Anúncio"
                  fields={[]}
                  media={anuncioMedia}
                />
                <Section
                  title="Planos do Usuário"
                  fields={
                    userDetails.plan_signatures.length > 0
                      ? userDetails.plan_signatures.map((plano, index) => ({
                          label: index === 0 ? `🔥 Plano Atual (ID: ${plano.id})` : `Plano ID: ${plano.id}`,
                          value: `Válido até: ${plano.expires_at} - Valor: R$ ${plano.value.toFixed(2)}`,
                          highlight: index === 0, // Adiciona um campo para destacar
                        }))
                      : [{ label: "Nenhum plano encontrado", value: "-" }]
                  }
                />

                <Section
                    title="Cupons do Usuário"
                    fields={userDetails.coupons_used.length > 0 ? userDetails.coupons_used.map((cupom) => ({
                      label: `Código: ${cupom.code}`,
                      value: (
                        <>
                          Tipo: {cupom.type} <br />
                          Valor: R$ {cupom.value} <br />
                          Usado em: {cupom.used_at}
                        </>
                      ),
                    })) : [{ label: "Nenhum cupom encontrado", value: "-" }]}
                  />

              </>
            ) : (
              <>
                <S.ContentRow>
                  <Section
                    title="Informações Pessoais"
                    fields={[
                      { label: "Nome", value: userDetails.name },
                      { label: "Sobrenome", value: userDetails.lastname },
                      { label: "Sexo", value: userDetails.sexo === "m" ? "Masculino" : "Feminino" },
                      { label: "Data de Nascimento", value: userDetails.dtnascimento },
                      { label: "E-mail", value: userDetails.email },
                      { label: "Celular", value: userDetails.cellphone },
                      { label: "Telefone", value: userDetails.telephone },
                    ]}
                  />
                  <Section
                    title="Endereço"
                    fields={[
                      { label: "Cidade", value: userDetails.cidade_nome },
                      { label: "Estado", value: userDetails.estado_nome },
                    ]}
                  />
                  
                </S.ContentRow>
                
              </>
            )}
            
          </S.Content>
          
        </S.AllContainer>
      ) : (
        <p>Nenhum detalhe disponível para o usuário.</p>
      )}
    </MainLayout>
  );
};

export default Detail;
