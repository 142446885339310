import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined"
import Box from "@mui/material/Box"
import Modal from "@mui/material/Modal"
import Typography from "@mui/material/Typography"
import * as React from "react"
import * as S from "./style"
import { useState } from "react"
import { TextField } from "@mui/material"
import api from "../../../services"
import RepproveModal from "../repprove-modal"

const style = {
  position: "absolute",
  borderRadius: "10px",
  width: `40%`,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  maxHeight: "80vh",
  outline: `1px solid #d9d9d9`,
}

export default function ValidateChangeRequest({ fetchChangRequests, changeRequest }) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleValidate = async (approve, reason) => {
    try {
      await api.patch(
        `/api/admin/announcement-change-request/${changeRequest.id}`,
        {
          approve,
          reason,
        }
      );
      fetchChangRequests();
      setOpen(false);
      alert("Solicitação validada com sucesso!");
    } catch (error) {
      console.log(error);
      alert(`Falha ao validar alteração: ${error.response.data.message}`);
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <S.Button onClick={() => setOpen(true)}>
        Validar
      </S.Button>
      {open && (
        <Modal
          open={open}
          onClose={setOpen}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <S.Icon>
              <CloseOutlinedIcon disabled={loading} onClick={() => setOpen(false)} />
            </S.Icon>

            <Typography id="modal-modal-description" sx={{ 
              display: "flex", 
              flexDirection: "column",
              maxHeight: "60vh",
              mt: 2,
            }}
            >
              <S.ContainerTitle>
                <S.Text>Validar Alteração</S.Text>
              </S.ContainerTitle>
              <Box sx={{ 
                display: "flex",
                flexDirection: "column",
                overflowY: "auto"
              }}>
                <S.SubtitleWrapper style={{
                  alignItems: "center"
                }}>
                  <img
                    src={changeRequest.logo}
                    style={{
                      borderRadius: "100%",
                      width: "30%",
                      marginBottom: changeRequest.logo ? 10 : 0
                    }}
                  />
                </S.SubtitleWrapper>
                <S.SubtitleWrapper>
                  Nome
                  <TextField
                    value={changeRequest.name}
                    style={{
                      marginBottom: 10
                    }}
                    readOnly
                  />
                </S.SubtitleWrapper>
                <S.SubtitleWrapper>
                  Descrição
                  <TextField
                    value={changeRequest.description}
                    style={{
                      marginBottom: 10
                    }}
                    readOnly
                  />
                </S.SubtitleWrapper>
                <S.SubtitleWrapper>
                  Telefone
                  <TextField
                    value={changeRequest.contact_number}
                    style={{
                      marginBottom: 10
                    }}
                    readOnly
                  />
                </S.SubtitleWrapper>
                <S.SubtitleWrapper>
                  Localização
                  <TextField
                    value={changeRequest.location.length === 2 ? `${changeRequest.location[1]}/${changeRequest.location[0]}` : ""}
                    style={{
                      marginBottom: 10
                    }}
                    readOnly
                  />
                </S.SubtitleWrapper>
                <S.SubtitleWrapper>
                  Ramo de Negócio
                  <TextField
                    value={changeRequest.ramo_negocio.descricao}
                    style={{
                      marginBottom: 10
                    }}
                    readOnly
                  />
                </S.SubtitleWrapper>
                <S.SubtitleWrapper>
                  Status
                  <TextField
                    value={changeRequest.status.descricao}
                    readOnly
                  />
                </S.SubtitleWrapper>
              </Box>
              <S.BottomInfo>
                <S.BottomButtons>
                  <RepproveModal handleValidate={handleValidate}/>
                  <S.DefaultButton disabled={loading} onClick={() => handleValidate(true)}>
                    <p>Aprovar</p>
                  </S.DefaultButton>
                </S.BottomButtons>
              </S.BottomInfo>
            </Typography>
          </Box>
        </Modal>
      )}
    </>
  )
}