import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Detail from "./pages/Home/detail";
import Consumidores from "./pages/consumidores";
import PushNotification from "./pages/PushNotification";
import Coupons from "./pages/coupons";
import AnnouncementChangeRequest from "./pages/announcement-change-request";

export default function Router() {
  return (
    <Routes>
      <Route exact path="/" element={<Login />} />
      <Route exact path="/home" element={<Home />} />
      <Route exact path="/consumidores" element={<Consumidores />} />
      <Route exact path="/push-notification" element={<PushNotification />} />
      <Route exact  path="/detail/:type/:id" element={<Detail />}/>
      <Route exact  path="/coupons" element={<Coupons />}/>
      <Route exact  path="/announcement-change-request" element={<AnnouncementChangeRequest />}/>
    </Routes>
  );
}
