import { CircularProgress, Pagination } from "@mui/material"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import { useEffect, useState } from "react"
import MainLayout from "../../layouts/MainLayout"
import api from "../../services"
import { phoneMask } from "../../utils/masks"
import * as S from "./style"
import { Link } from "react-router-dom"
import Papa from "papaparse"
import CreateModal from "./components/CreateModal"
import EditModal from "./components/EditModal"

export default function Coupons() {
  const [search, setSearch] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useState(search);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [loading, setLoading] = useState(false);
  const [coupons, setCoupons] = useState([]);

  async function fetchCoupons() {
    try {
      setLoading(true)
      const res = await api.get("/api/admin/coupons", {
        params: {
          page: page,
          ...(search && { search: debouncedSearch }),
        }
      })

      setCoupons(res.data.data);
      setPageCount(res.data.last_page);
      setLoading(false)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    fetchCoupons();
  }, [page, debouncedSearch]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedSearch(search.toLowerCase());
    }, 300);
  
    return () => clearTimeout(timer);
  }, [search]);
  
  
  return (
    <MainLayout title="Cadastro de cupons do App">
      <S.Header>
        <S.Input
          placeholder="Busque pelo código do cúpom"
          onChange={(e) => setSearch(e.target.value )}
        />
        <CreateModal fetchCoupons={fetchCoupons}/>
      </S.Header>
      {loading ? (
        <S.ContainerLoading>
          <CircularProgress style={{ color: "#919DF2" }} />
        </S.ContainerLoading>
      ) : (
        <>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="consumer table">
              <TableHead sx={{ backgroundColor: "#B2B2B2" }}>
                <TableRow>
                  <TableCell>Código</TableCell>
                  <TableCell>Tipo</TableCell>
                  <TableCell>Valor</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Uso Máximo por Usuário</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {coupons?.length > 0 && coupons?.map((item) => (
                  <TableRow
                    key={item.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell>{item.code.toUpperCase()}</TableCell>
                    <TableCell>
                      {item.type === "percent-discount"
                        ? "Desconto Percentual"
                        : item.type === "value-discount"
                        ? "Desconto em Valor"
                        : "Duração em Dias no Plano"
                      }
                    </TableCell>
                    <TableCell>{item.value}</TableCell>
                    <TableCell>{item.active ? "Ativo" : "Inativo"}</TableCell>
                    <TableCell>{item.max_uses}</TableCell>
                    <TableCell align="right">
                      <EditModal fetchCoupons={fetchCoupons} coupon={item}/>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {coupons?.length <= 0 && (
            <div style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px"
            }}>
              Nenhum registro encontrado
            </div>
          )}
          <div style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px"
          }}>
            <Pagination count={pageCount} page={page} onChange={(_, v) => setPage(v)}/>
          </div>
        </>
      )}
    </MainLayout>
  )
}